
import { Component, Vue } from 'vue-property-decorator';
import {
  VideoPlayer,
  Modal
} from '@/shared/components';
import {
  HelpAdvert
} from './components';
import { getHelpVideo } from '@/helpers';
import { VideoSource } from '@/models';
import {
  NeSection,
  NeIconButton
} from '@ne/ne-vue2-lib';

interface HelpItem {
  image: string;
  video: string;
  title: string;
  description: string;
  descriptionItems: string[];
}

@Component({
  name: 'Help',
  components: {
    VideoPlayer,
    HelpAdvert,
    Modal,
    NeIconButton,
    NeSection
  }
})
export default class Help extends Vue {
  isModalOpen = false;
  videoSource: VideoSource | null = null;
  helpItems: HelpItem[] = [
    {
      image: '01a-how-to-prepare-a-test.png',
      video: 'composer1',
      title: 'Jak przygotować test?',
      description: 'Jeśli chcesz przygotować od początku nowy test, skorzystaj z zakładki Utwórz test:',
      descriptionItems: [
        'wybierz zadania do grupy A, grupa B utworzy się automatycznie,',
        'zmieniaj kolejność zadań, dodawaj miejsce na odpowiedzi,',
        'klucz odpowiedzi utworzy się automatycznie.'
      ]
    },
    {
      image: '02a-how-to-generate-a-test-for-the-next-group.png',
      video: 'composer2',
      title: 'Jak wygenerować test dla kolejnej grupy?',
      description:
        'Jeśli chcesz utworzyć kolejną grupę testu, na ekranie <em>Edycja grup</em> skorzystaj z przycisku Dodaj nową grupę:',
      descriptionItems: ['możesz utworzyć aż 8 grup testu,', 'klucz odpowiedzi wygeneruje się automatycznie.']
    },
    {
      image: '03a-how-to-use-test-database.png',
      video: 'tests',
      title: 'Jak korzystać z Bazy testów?',
      description: 'W Bazie testów są zapisywane wszystkie testy, również te generowane przez Ciebie. Możesz:',
      descriptionItems: [
        'pobrać komplet plików – testy i klucz odpowiedzi za pomocą przycisku <em>Pobierz</em>,',
        'przeglądać wszystkie grupy testu w widoku <em>Podgląd</em>; w oknie podglądu można też pobierać pojedyncze pliki, np. tylko test,',
        'edytować i zmieniać zadania w teście. Edytowane testy Nowej Ery zapiszą się pod nową nazwą.'
      ]
    },
    {
      image: '04a-how-to-use-exercise-database.png',
      video: 'exercises',
      title: 'Jak korzystać z Bazy zadań?',
      description:
        'W Bazie zadań są zapisywane wszystkie zadania, również te przygotowane przez Ciebie. Bazę zadań można przeszukiwać za pomocą filtrów. Możesz:',
      descriptionItems: [
        'wybrać zadanie i rozpocząć tworzenie nowego testu, korzystając z przycisku <em>Dodaj do testu</em>,',
        'edytować wybraną wersję zadania po jej zaznaczeniu i użyciu przycisku <em>Utwórz nową wersję</em>,',
        'przeglądać wszystkie wersje zadań i klucz odpowiedzi po użyciu przycisku <em>Podgląd</em>.'
      ]
    },
    {
      image: '05a-add-exercise-to-database.png',
      video: 'exerciseComposer',
      title: 'Jak dodać nowe zadanie do Bazy zadań?',
      description: 'Jeśli chcesz dodać własne zadanie do Bazy zadań użyj zakładki Utwórz zadanie:',
      descriptionItems: [
        'wybierz dział lub temat, do którego tworzysz zadanie,',
        'wpisz treść zadania i odpowiedzi,',
        'możesz dodać też załącznik – tekst, ilustrację czy mapę. Załącznik będzie dostępny w dodatkowym pliku – nie zajmuje miejsca w teście.'
      ]
    }
  ];

  getImage (image: string): void {
    return require(`@/assets/images/help/${image}`);
  }

  showHelpVideo (choice: string): void {
    this.isModalOpen = true;
    this.videoSource = getHelpVideo(this.$apiURL, choice);
  }
}
