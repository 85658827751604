
import { Component, Vue } from 'vue-property-decorator';
import {
  NeSection,
  NeSimpleGrid,
  NeSimpleGridItem
} from '@ne/ne-vue2-lib';

@Component({
  name: 'HelpAdvert',
  components: {
    NeSection,
    NeSimpleGrid,
    NeSimpleGridItem
  }
})
export default class HelpAdvert extends Vue {}
